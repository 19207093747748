/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { SEO } from 'data/seo';
// import { useStaticQuery, graphql } from "gatsby"
// import favicon from 'images/favicon.png';

function Seo({ lang, meta, title }) {
  // const { site } = useStaticQuery(
  //   graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //           title
  //           description
  //           author
  //         }
  //       }
  //     }
  //   `
  // )

  // const SEO.description = description || site.siteMetadata.description
  // const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title='the White Distance'
      titleTemplate={title ? `%s | ${title}` : null}
      link={[
        {
          rel: "preconnect",
          href: "https://fonts.googleapis.com",
        },
        {
          rel: "preconnect",
          href: "https://fonts.gstatic.com",
          crossorigin: true
        },
        {
          rel: "stylesheet",
          href: "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap",
        },
        // {
        //   rel: 'shortcut icon',
        //   href: favicon,
        // },
      ]}
      meta={[
        {
          name: 'title',
          content: SEO.title,
        },
        {
          name: `description`,
          content: SEO.description,
        },
        {
          itemprop: 'title',
          content: SEO.title,
        },
        {
          itemprop: `description`,
          content: SEO.description,
        },
        {
          itemprop: `image`,
          content: SEO.image,
        },
        {
          name: 'keywords',
          content: SEO.title,
        },
        {
          property: `og:title`,
          content: SEO.title,
        },
        {
          property: `og:description`,
          content: SEO.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: SEO.image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata?.author || ``,
        // },
        {
          name: `twitter:title`,
          content: SEO.title,
        },
        {
          name: `twitter:description`,
          content: SEO.description,
        },
        {
          name: `twitter:image`,
          content: SEO.image,
        },
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
