import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';
import {
  ICMenu,
  ICClose,
  ICRectangle,
  ICChevronLeft,
  ICRightOutline,
  ICLeftOutline
} from './svg';

const IconWrapper = ({ SVGComponent, svgProps, ...props }) => (
  <Icon
    {...props}
    component={() => <SVGComponent {...svgProps} fill="none" />}
  />
);

IconWrapper.propTypes = {
  SVGComponent: PropTypes.any,
  svgProps: PropTypes.object,
}

export const MenuIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMenu} />
);

export const CloseIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICClose} />
);

export const RectangleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICRectangle} />
);

export const ChevronLeftIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICChevronLeft} />
);

export const RightOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICRightOutline} />
);
export const LeftOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLeftOutline} />
);