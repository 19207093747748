export const CLASS_UTILITY = `
/*----------Height--------------------*/
  .h-full {
    height: 100%;
  }
  .h-screen {
    height: 100vh;
  }

/*----------Width--------------------*/
  .w-full {
    width: 100%;
  }
  .w-screen {
    width: 100vh;
  }

  /*----------Margin--------------------*/

  .mt-20 {
    margin-top: 20px;
  }

  .mb-16 {
    margin-bottom: 16px;
  }
  
  .mb-5 {
    margin-bottom: 5px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-60 {
    margin-bottom: 60px;
  }

  .mb-80 {
    margin-bottom: 80px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .ml-16 {
    margin-left: 16px;
  }

  .mr-5 {
    margin-right: 5px;
  }

  .mb-7 {
    margin-bottom: 7px;
  }

  .m-auto {
    margin: auto;
  }
  
  /*----------Padding--------------------*/
  .p-0 {
    padding: 0;
  }

  /*----------Font weight------*/
  .fw-bold {
    font-weight: bold;
  }
  .fw-500 {
    font-weight: 500;
  }
  .fw-600 {
    font-weight: 600;
  }

  /*----------Font size------*/
  .fs-16 {
    font-size: 16px;
  }

  .fs-12 {
    font-size: 12px;
  }

  /*----------Display------*/

  .d-none {
    display: none;
  }

  .d-flex {
    display: flex;
  }

  /*----------Flex------*/
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .flex-end {
    align-items:flex-end;
    justify-content:flex-end;
  }

  .flex-center-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .flex-center-end {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .flex-column {
    flex-direction:column;
  }

  .flex-row-reverse {
    flex-direction: row-reverse;
  }
  
  .flex-wrap {
    flex-wrap: wrap;
  }
  
  .align-items-center {
    align-items: center;
  }

  .align-items-end {
    align-items: flex-end;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .justify-content-center {
    justify-content: center
  }

  .justify-content-end {
    justify-content: flex-end;
  }

  .flex-auto {
    flex: 1 1 auto;
  }

  .flex-1 {
    flex: 1;
  }

  /*----------Text--------------------*/
  .text-uppercase {
    text-transform: uppercase;
  }

  .text-black {
    color: #000000;
  }

  .text-pre-wrap {
    white-space: pre-wrap;
  }

  .text-center {
    text-align: center;
  }

  .ellipsis-3-t {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  .ellipsis-2-t {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  .ellipsis-t {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /*---------------------Shadow--------------------*/
  .shadow-sm {
    box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
  }

  /*---------------------Cursor--------------------*/

  .pointer {
    cursor: pointer
  }


`;
