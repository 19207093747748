import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"

import theme from "configs/theme.json"
import GlobalStyle from "styles/globalStyle"
import TogglePlayer from "components/common/TogglePlayer"

import "./Wrapper.less"

const Wrapper = ({ children }) => (
  <ThemeProvider theme={theme}>
    {children}
    <GlobalStyle />
    <TogglePlayer />
  </ThemeProvider>
)

Wrapper.propTypes = {
  children: PropTypes.any,
}

export default Wrapper
