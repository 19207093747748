import { CaretRightOutlined, PauseOutlined } from "@ant-design/icons"
import React from "react"
import Button from "antd/lib/button"
import useMultiAudioBackground from "hooks/useMultiAudioBackground"
import { TogglePlayerStyles } from './styles'

const TogglePlayer = () => {
  const { playing, onStart, onPause } = useMultiAudioBackground()

  return (
    <TogglePlayerStyles>
      <Button
        shape="circle"
        className="btn-gray"
        icon={playing ? <PauseOutlined /> : <CaretRightOutlined />}
        onClick={playing ? onPause : onStart}
      />
    </TogglePlayerStyles>
  )
}

export default TogglePlayer
