import { useState, useEffect } from "react"

const audio = (typeof Audio != "undefined") ? new Audio() : {}

const paths = [
  // "https://www.w3schools.com/html/horse.mp3",
  "/audio/audio1.mp3",
  // 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3',
  '/audio/audio2.mp3',
  '/audio/audio3.mp3',
  '/audio/audio4.mp3',
]

const useMultiAudioBackground = () => {
  const [playing, setPlaying] = useState(!audio.paused);

  // const currentAudioRef = useRef(0);

  const playMultiAudio = (urls, idx = 0) => {
    audio.src = urls[idx]

    audio.play()

    // currentAudioRef.current = idx;

    audio.onended = e => {
      playMultiAudio(urls, idx >= urls.length - 1 ? 0 : idx + 1)
    }
  }

  const onStart = () => {
    if (!playing) {
      setPlaying(true)
      if (audio.duration > 0 && audio.paused && !audio.ended) audio.play()
      else playMultiAudio(paths)
    }
  }

  useEffect(() => {
    const audioStartEle = document.getElementById("audio-start")

    if (audioStartEle) {
      audioStartEle.addEventListener("click", () => {
        onStart();
      })

      return () => {
        audioStartEle.removeEventListener("click", () => {
          onStart();
        })
      }
    }
  }, []); // eslint-disable-line

  const onPause = () => {
    setPlaying(false)
    audio.pause()
  }

  return { playing, onStart, onPause }
}

export default useMultiAudioBackground
