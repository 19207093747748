import { createGlobalStyle } from "styled-components"
// import theme from "./theme.json"
import { CLASS_UTILITY } from './utilities';

const GlobalStyle = createGlobalStyle`
  ${CLASS_UTILITY}
  html {
    scroll-behavior: smooth;
    font-family: 'Open Sans';
  }
  div {
    scroll-behavior: smooth;
  }
  body {
    scroll-behavior: smooth;
  }
  
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }

  .container-landing {
    padding-top: 100px;
    padding-bottom: 100px;

    @media only screen and (max-width: 992px) {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    @media only screen and (max-width: 425px) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  .container-wrapper {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding-left: 130px;
    padding-right: 130px;
    position: relative;
    width: calc(100% - 260px);

    @media only screen and (max-width: 992px) {
      width: calc(100% - 100px);
      padding-left: 50px;
      padding-right: 50px;
    }

    @media only screen and (max-width: 425px) {
      width: calc(100% - 60px);
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .btn-gray {
    background: ${({ theme }) => theme.background.gray} !important;
    border-color: ${({ theme }) => theme.background.gray} !important;
    &:hover, &:active {
      border-color: ${({ theme }) => theme.palette.primary} !important;
      background: ${({ theme }) => theme.palette.primary} !important;
      color: ${({ theme }) => theme.text.primaryBtnTextColor} !important;
    }
  }

  .btn-outline-primary {
    border-color: ${({ theme }) => theme.palette.primary} !important;
  }

  .ant-image-preview-mask {
    background-color: #000 !important;
  }

`

export default GlobalStyle
