import styled from "styled-components"

const PublicLayoutStyles = styled.div`
  position: relative;

  .menu-button {
    position: fixed;
    top: 40px;
    left: 40px;
    z-index: 10;
  }

  @media only screen and (max-width: 425px) {
    .menu-button {
      top: 20px;
      left: 20px;
    }
  }
`

export default PublicLayoutStyles
