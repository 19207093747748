import styled from 'styled-components';
import { Drawer } from "antd"

const MenuLeftStyles = styled.div`
  
`;

export const MenuLeftDrawerStyles = styled(Drawer)`

  .ant-drawer-content{
    overflow: visible;
  }

  .ant-drawer-body {
    padding: 120px 40px;
  }

  .menu-left-item {
    font-size: 36px;
    display: block;
    line-height: 1;
    
    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  .menu-close-button {
    position: absolute;
    top: 40px;
    right: 0;
    transform: translateX(50%);
  }
`;

export default MenuLeftStyles;