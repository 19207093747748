import React, { useState } from "react"

import { Button } from "antd"
import { MenuIcon, CloseIcon } from "../../common/SVGIcons"

import MenuLeftStyles, { MenuLeftDrawerStyles } from "./styles"
import { Link } from "gatsby"

const MenuLeft = () => {
  const [visible, setVisible] = useState(false)

  const toggleDrawer = () => {
    setVisible(!visible)
  }

  return (
    <MenuLeftStyles>
      {!visible && (
        <Button
          onClick={toggleDrawer}
          shape="circle"
          className="btn-gray menu-button"
          icon={<MenuIcon />}
        />
      )}

      <MenuLeftDrawerStyles
        placement={"left"}
        closable={false}
        onClose={toggleDrawer}
        visible={visible}
        width={360}
      >
        <Link to="/" className="menu-left-item">
          Home
        </Link>
        <Link to="/artists" className="menu-left-item">
          Artists
        </Link>
        <a className="menu-left-item" href="https://www.3dartvn.com/submission/360_demo/complex1" target="_blank">
          Exhibition
        </a>
        {/* <Link to="#" className="menu-left-item">
          Exhibition
        </Link> */}
        <Link to="/aboutMAP" className="menu-left-item">
          About MAP
        </Link>
        <Link to="/aboutMAP#contactsMap" className="menu-left-item">
          Contacts
        </Link>

        {visible && (
          <Button
            onClick={toggleDrawer}
            shape="circle"
            className="btn-gray menu-close-button"
            icon={<CloseIcon />}
          />
        )}
      </MenuLeftDrawerStyles>
    </MenuLeftStyles>
  )
}

export default MenuLeft
