/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

import Wrapper from "./Wrapper"
import MenuLeft from "./MenuLeft"

import PublicLayoutStyles from "./styles"

const PublicLayout = ({ children }) => {

  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <Wrapper>
      <PublicLayoutStyles className="public-layout">
        {/* <Header siteTitle={data.site.siteMetadata?.title || `Title`} /> */}
        <MenuLeft />
        <div>{children}</div>
        {/* <footer
           style={{
             marginTop: `2rem`,
           }}
         >
           © {new Date().getFullYear()}, Built with
           {` `}
           <a href="https://www.gatsbyjs.com">Gatsby</a>
         </footer> */}
      </PublicLayoutStyles>
    </Wrapper>
  )
}

PublicLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PublicLayout
